import React from 'react';

const Popup = ({ active, setActive, children }) => {
  return (
    <div className={active ? 'modal active' : 'modal'} onClick={() => setActive(false)}>
      <div
        className={active ? 'modal__content active' : 'modal__content'}
        onClick={e => e.stopPropagation()}>
          <span className="close-icon" onClick={() => setActive(false)}>X</span>
        {children}
      </div>
    </div>
  );
};

export default Popup;