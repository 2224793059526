import Button from './Button';
import { useForm } from 'react-hook-form';
import emailjs from 'emailjs-com';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.min.css';

const OrderForm = () => {
  const {
    register,
    handleSubmit,
    reset,
    formState: { errors },
  } = useForm();

  // Function that displays a success toast on bottom right of the page when form submission is successful
  const toastifySuccess = () => {
    toast('Заявка отправлена!', {
      position: 'bottom-right',
      autoClose: 5000,
      hideProgressBar: true,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: false,
      className: 'toast',
      toastId: 'notifyToast',
    });
  };

  // Function called on submit that uses emailjs to send email of valid contact form
  const onSubmit = async data => {
    // Destrcture data object
    const { name, size, message } = data;
    try {
      const templateParams = {
        name,
        size,
        message,
      };

      await emailjs.send(
        (process.env.REACT_APP_SERVICE_ID = 'service_riq161i'),
        (process.env.REACT_APP_TEMPLATE_ID = 'template_pndusxn'),
        templateParams,
        (process.env.REACT_APP_USER_ID = 'user_3BzwCdPDVJyacvpHFHkXF'),
      );

      reset();
      toastifySuccess();
    } catch (e) {
      console.log(e);
    }
  };

  return (
    <div>
      <div className="contactForm">
        <form id="contact-form" onSubmit={handleSubmit(onSubmit)} noValidate>
          {/* Row 1 of form */}
          <div className="form-row">
            <div className="form-row-field">
              <input
                type="text"
                name="name"
                {...register('name', {
                  required: true,
                  pattern: /^(\+)?(\(\d{2,3}\) ?\d|\d)(([ \-]?\d)|( ?\(\d{2,3}\) ?)){5,12}\d$/,
                })}
                className="form-control formInput"
                placeholder="тел*:89997770000"
                autoComplete="off"></input>
              {errors.name && <span className="error">Введите номер телефона</span>}
            </div>

            <div className="form-row-field">
              <input
                type="text"
                name="size"
                {...register('size', {
                  required: true,
                  pattern: /^[ 0-9]+$/,
                })}
                className="form-control formInput"
                placeholder="объем аквариума(л)*"
                autoComplete="off"></input>
              {errors.size && <span className="error">Объем в цифрах (напр: 200)</span>}
            </div>
          </div>
          
          {/* Row 2 of form */}
          <div className="form-row">
            <textarea
              rows={3}
              name="message"
              id="message"
              {...register('message', {
                required: false,
              })}
              className="form-control formInput"
              placeholder="комментарии (напишите нагрузку на систему, 
                        особенности аквариума и тд)"
              autoComplete="off">
              
              </textarea>
          </div>

          <div className="button_container">
            <Button className="submit-btn" type="submit">
            ОСТАВИТЬ ЗАЯВКУ
            </Button>
          </div>
        </form>
      </div>
      <ToastContainer />
    </div>
  );
};

export default OrderForm;
