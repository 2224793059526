import React, {useState} from 'react';
import BurgerMenu from './BurgerMenu';
import insta from '../Image/insta.png'
import whatsApp from '../Image/wa.png'

const BurgerContent = () => {
    const [menuActive, setMenuActive] = useState(false);
    const [status, setStatus] = useState('close');
  
  const items = [
    { value: <h1>Reef Equipment</h1> },
    { value: <h1>+79061596599</h1> },
    { value: <p>производство оборудования для морского аквариума</p>},
    { value: <p>доставка по всей России и миру</p>},
    { value: <p>работаем: ежедневно с 10:00 до 19:00</p>},
    { value: <h1></h1> },
    { value: <img src={insta}  alt="instagram image" />,  href: 'https://www.instagram.com/reefequipment/', },
    { value: <img src={whatsApp}  alt="whats app image" />,  href: 'https://wa.me/79061596599'},
  
  ];

  return (
    <>
      <div className="burger-nav">
        <div className="burger-btn" 
        
        onClick={() => {setMenuActive(!menuActive); setStatus(status === 'open' ? 'close' : 'open')}}>
          <i className={status}></i>
          <i className={status}></i>
          <i className={status}></i>
        </div>
      </div>

      <BurgerMenu active={menuActive} setActive={setMenuActive} items={items} />
    </>
  );
};

export default BurgerContent;
