import React from 'react';
import classNames from 'classnames';
//import PropTypes from 'prop-types';

function Button(props) {
  return (
    <button
      onClick={props.onClick}
      onSubmit={props.onSubmit}
      className={classNames('button', {
        'button--red': props.red,
        'button--disabled': props.disabled,
      })}>
      {props.children}
    </button>
  );
}

export default Button;
