import React from 'react';
import logo from '../Image/small-logoRE.png';
import insta from '../Image/insta.png'
import whatsApp from '../Image/wa.png'

function Footer() {
  return (
    <div className="general-black">
      <div className="container">
        <div className="general-footer">
          <div className="logo-block">
            <img src={logo} alt="logo"/>
            <div className="logo-footer"> <h1>Reef Equipment</h1></div>
      
          </div>

          <div className="logo-block">
            <img src={insta} alt="instagram image" />
            <div className="instagram">
            <p>мы в инстаграмм</p>
            <a href="https://www.instagram.com/reefequipment/">@reefequipment</a>
            </div>
          </div>

          <div className="logo-block">
          <img src={whatsApp} alt="whats app image"></img>
           <div className="whats-app">
           <p>напишите нам</p>
            <a href="https://wa.me/79061596599">  +7(906)1596599</a>
            </div>
          </div>


        </div>
      </div>
    </div>
  );
}

export default Footer;
