import React from 'react';
//import rectangle from '../Image/rectangle.png';
import skimmer from '../Image/skimmer.png';
import mixer from '../Image/mixer.png';
import biopelet from '../Image/biopelet.png'
import calcium from '../Image/calcium.png'

function Equipment() {
  return (
    <div className="container">
        <div className="equipment-block-name">
        <p>наше оборудование</p>
        </div>
      
      <div className="general-equipment">
        <div className="two-equipment-block">
        <div className="equipment-block">
        <h2>от 17.000 р</h2>
          <div className="rectangle"></div>
          
          <img src={skimmer} alt="skimmer photo" />
          <p>скиммеры</p>
        </div>

        <div className="equipment-block">
        <h2>от 17.000 р</h2>
          <div className="rectangle"></div>
          <img src={biopelet} alt="biopelet reactor photo" />
          <p>биопелет реакторы</p>
        </div>
        </div>
        
        <div className="two-equipment-block">
        <div className="equipment-block">
        <h2>от 18.500 р</h2>
          <div className="rectangle"></div>
          <img src={calcium} alt="calcium photo" />
          <p>кальциевые реакторы</p>
        </div>

        <div className="equipment-block">
        <h2>от 8.000 р</h2>
          <div className="rectangle"></div>
          <img src={mixer} alt="mixer photo" />
          <p>мешалки</p>
        </div>
        </div>

        


      </div>
    </div>
  );
}

export default Equipment;
