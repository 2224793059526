import React, { useState } from 'react';
import emailjs from 'emailjs-com';
import Button from './Button';
import { useForm } from 'react-hook-form';
import callback from '../Image/icon-call.png';

export default function PopupForm() {

  const {
    register,
    handleSubmit,
    reset,
    formState: { errors },
  } = useForm();

  const [isModalOpen, setModalState] = useState(true);
  const [onMessage, setMessage] = useState(false);

 // Function called on submit that uses emailjs to send email of valid contact form
 const sendEmail = async data => {
  // Destrcture data object
  const { name, tel, message } = data;
  setModalState(false);
    setMessage(true);  
  try {
    const templateParams = {
      name,
      tel,
      message,
    };

    await emailjs.send(
      (process.env.REACT_APP_SERVICE_ID = 'service_riq161i'),
        (process.env.REACT_APP_TEMPLATE_ID = 'template_569bioe'),
        templateParams,
        (process.env.REACT_APP_USER_ID = 'user_3BzwCdPDVJyacvpHFHkXF'),
    );

    reset();
  } 
  
  catch (e) {
    console.log(e);
  }
};


  return (
    <div>
      {onMessage ? (
        <>
          <p>Спасибо! Мы обязательно перезвоним вам.</p>
        </>
      ) : null}

      {isModalOpen ? (
        <>
         <div className="call-block">
          <div className="callback">
            <img src={callback} alt="call icon"></img>
          </div>

          <p>Оставьте свои контактные данные и мы перезвоним вам</p>
        </div>

          <form onSubmit={handleSubmit(sendEmail)}>
            {/* Row 1 of form */}

            <div className="form-row">
              <input
                type="text"
                name="name"
                {...register('name', {
                  required: { value: true, message: 'Пожалуйста, введите ваше имя' },
                  maxLength: {
                    value: 22,
                    message: 'Пожалуйста, сократите до 22 символов',
                  },
                })}
                className="form-control formInput"
                placeholder="Имя*"
                autoComplete="off"></input>
              {errors.name && <span className="error">{errors.name.message}</span>}
            </div>

            {/* Row 2 of form */}
            <div className="form-row">
              <input
                type="text"
                name="tel"
                {...register('tel', {
                  required: true,
                  pattern: /^(\+)?(\(\d{2,3}\) ?\d|\d)(([ \-]?\d)|( ?\(\d{2,3}\) ?)){5,12}\d$/,
                })}
                className="form-control formInput"
                placeholder="тел*:89997770000"
                autoComplete="off"></input>
              {errors.tel && <span className="error">Введите номер телефона</span>}
            </div>

            {/* Row 3 of form */}
            <div className="form-row">
              <textarea
                rows={3}
                name="message"
                id="message"
                {...register('message', {
                  required: false,
                })}
                className="form-control formInput"
                placeholder="Комментарии (не обязательно)"
                autoComplete="off"></textarea>
            </div>

            <div className="button_container">
              <Button red className="submit-btn" type="submit">
                заказать звонок
              </Button>
            </div>
          </form>
        </>
      ) : null}
    </div>
  );
}
