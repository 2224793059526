import React from 'react';
import production from '../Image/icon-production100.png';
import customer from '../Image/icon-customer100.png';
import price from '../Image/icon-price100.png';
import delivery from '../Image/icon-delivery100.png';
import warranty from '../Image/icon-warranty100.png';
import support from '../Image/icon-suport100.png';

function Benefits() {
  return (
    <div className="container">
      <div className="general-equipment">
        <div className="benefits">
          <div className="benefit">
            <img src={production} alt="own production" />
            <p>
              собственное <br /> производство
            </p>
          </div>
          <div className="benefit">
            <img src={delivery} alt="international delivery" />
            <p>доставка по России и всему миру</p>
          </div>
        </div>

        <div className="benefits">
          <div className="benefit">
            <img src={customer} alt="customers size" />
            <p>оборудование по вашим размерам</p>
          </div>

          <div className="benefit">
            <img src={warranty} alt="1 year warranty" />
            <p>гарантия 1 год на все оборудование </p>
          </div>
        </div>

        <div className="benefits">
          <div className="benefit">
            <img src={price} alt="low price" />
            <p>
              низкие <br /> цены
            </p>
          </div>

          <div className="benefit">
            <img src={support} alt="support" />
            <p>постгарантийная поддержка</p>
          </div>
        </div>
      </div>
    </div>
  );
}

export default Benefits;
