import React, { useState } from 'react';
import logo from '../Image/logoRE.png';
import Popup from './Popup';
import PopupForm from './PopupForm';

function Header() {
  const [modalActive, setModalActive] = useState(false);

  return (
    <>
      <div className="container">
        <div className="header">
          <div className="logo" style={{ zIndex: '500' }}>
            <a href="/">
              <img src={logo} alt="logo" />
            </a>
          </div>
          <div className="name">
              <h1>Reef Equipment</h1>
              <p>производство оборудования</p>
              <p>для морского аквариума</p>
          </div>
          <div className="contacts">
              <h1>+79061596599</h1>
              <p>ежедневно с 10:00 до 19:00</p>
              <a href="#!" onClick={() => setModalActive(true)}>заказать звонок</a>
          </div>
        </div>
      </div>
      <div className="header-black"></div>

      <Popup active={modalActive} setActive={setModalActive}>
          <PopupForm />
        </Popup>

    </>
  );
}

export default Header;
