import React from 'react';

const BurgerMenu = ({items, active, setActive }) => {
  return (
    <div className={active ? "burger-menu active": "burger-menu"} onClick={() => setActive(false)}>
      <div className="burger-menu-content">
        <ul>
          {items.map((item, id) => (
            <li key={id}>
              <a href={item.href}>{item.value}</a>
            </li>
          ))}
        </ul>
      </div>
    </div>
  );
};

export default BurgerMenu;
