import React from 'react';
import './index.css';

import Header from './Components/Header';
import General from './Components/General';
import Benefits from './Components/Benefits';
import Equipment from './Components/Equipment';
import Footer from './Components/Footer';
import BurgerContent from './Components/BurgerContent';

function App() {
  return (
    <>
      {/*HEADER*/}
      <Header />

      {/*BURGER MENU*/}
      <BurgerContent />

      {/*GENERAL*/}
      <General />

      {/*BENEFITS*/}
      <Benefits />

      {/*EQUIPMENT*/}
      <Equipment />

      {/*FOOTER*/}
      <Footer />
    </>
  );
}

export default App;
