import React from 'react';
import fish from '../Image/fish-red.png';
import OrderForm from './OrderForm';

function Header() {
  return (
    <>
      <div className="general-black">
        <div className="container">
          <div className="general">
            <div className="fish" style={{ zIndex: '500' }}>
              <img src={fish} alt="fish" />
            </div>
            <div className="equipment">
             
              <div className="biopelet">
                <p>биопелет реакторы</p>
              </div>
              
              <div className="skimmer">
                <p>скиммеры</p>
              </div>
              
              <div className="calcium">
                <p>кальциевые реакторы</p>
              </div>

              <div className="form-container">
                <p>оставьте заявку на бесплатный подбор оборудования</p>
                <OrderForm />
              </div>
            </div>
          </div>

          <div className="general-years">
            <div className="years">
              <h2>5 лет</h2>
              <p>производим оборудование</p>
            </div>
            <div className="years">
              <h2>более 700</h2>
              <p>изготовленных единиц</p>
            </div>
            <div className="years">
              <h2>15+</h2>
              <p>клиентов в месяц </p>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default Header;
